body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}



html{
  overflow-x: hidden;
  background-color: #FFFCEF;
}
